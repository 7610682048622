import React from 'react'
import get from 'lodash/get'
import Card from 'react-bootstrap/Card'
import { Link } from 'gatsby'
import Img from './image'
import { contentfulMapToLocalized } from "../../helpers.js"
import styles from './ingredient.scss'

class Ingredient extends React.Component {
  constructor(props) {
    super(props);
    const node = this.props.node
    let contentfulIngredient = node.fields
    this.ingredient = contentfulMapToLocalized(contentfulIngredient)
  }
  render() {    
    return (
      <div className='contentful-ingredient'>
        <Card>
          <Img node={this.ingredient.image} className="card-img-top"/>
          <Card.Body>
            <Card.Title as="h3">{ this.ingredient.name }</Card.Title>
            { this.ingredient.tagLine ? (
              <Card.Text>{ this.ingredient.tagLine }</Card.Text>              
            ) : '' }
          </Card.Body>
        </Card>
      </div>
    )
  }
}

export default Ingredient
